// 职位详情页面
<template>
  <div class="position-detail">
    <div class="content wrap-v1">
      <div class="title line">
        IT产品 初级助理（J10861）
      </div>
      <div class="info line">
        <div class="item">
          <span>招聘类别：</span>
          <span class="text">校园招聘</span>
        </div>
        <div class="item">
          <span>工作性质：</span>
          <span class="text">全职</span>
        </div>
        <div class="item">
          <span>招聘人数：</span>
          <span class="text">2</span>
        </div>
        <div class="item">
          <span>发布时间：</span>
          <span class="text">2020-09-09</span>
        </div>
      </div>
      <div class="line">
        截止时间：2020-09-09
      </div>
      <div class="line">
        工作地点：广东省-深圳市
      </div>
      <div class="sub-title mt-3">工作职责：</div>
      <div class="need-list">
        <div class="need">
          1.
          5年以上的用户体验从业经验，尤其是曾经常和公司高层决策者探讨设计和产品；
        </div>
        <div class="need">
          2.
          对热门行业有深入跟踪了解，深度了解商业和设计的策略关系，善于剖析问题引导他人；
        </div>
        <div class="need">
          3.
          参与产品需求制定的整个过程，根据设计需求提炼出用户体验目标，可提出革新的交互解决方案，并推动方案
        </div>
        <div class="need">
          4.
          熟练灵活的应用设计方法，挖掘产品体验设计机会点，基于用户研究数据和设计机会洞察，制定产品体验设计
          方案，并对产品最终的用户体验负责；
        </div>
      </div>
      <div class="sub-title mt-6">任职资格：</div>
      <div class="need-list ">
        <div class="need">
          1.扎实的java基础知识功底，熟练掌握springmvc、springboot、mybatis等框架
        </div>
        <div class="need">
          2.熟练掌握关系型数据库的开发和使用（MySql），能熟练运用sql语言，sql优化等
        </div>
        <div class="need">
          3.熟悉其中一种消息队列，RabbitMQ，RocketMQ，Kafka等
        </div>
        <div class="need">
          4.熟练使用idea开发工具 ，git版本控制工具
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped lang="scss">
.position-detail {
  text-align: left;
  padding: 30px 0 120px;
}
.line {
  padding: 28px 0;
  font-size: 24px;
  color: #333333;
  line-height: 33px;
}
.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .text {
    color: #9c9c9c;
  }
}
.mt-6 {
  margin-top: 60px;
}
.mt-3 {
  margin-top: 30px;
}
.sub-title {
  font-size: 24px;
  color: #333333;
  line-height: 33px;
}
.need-list {
  font-size: 24px;
  color: #9c9c9c;
  line-height: 33px;
  .need {
    margin-top: 15px;
  }
}
</style>
